body {
  background-color: #171717;
  color: rgb(245, 237, 227);
}
.main-container {
  width: 80%;
  margin: auto auto;
  margin-top: 5%;
  position: relative;
  max-width: 1080px;
}
.container p {
  text-align: left;
  font-family: "Anonyms Pro", monospace;
}
.contact ul {
  list-style-type: none;
}
.container li {
  text-align: left;
}
.contact ul li a {
  text-decoration: none;
  color: aqua;
  font-family: "Anonyms Pro", monospace;
}
.button-for-cv {
  text-align: left;
}
.skills {
  font-family: "Anonyms Pro", monospace;
  text-align: left;
}
.para-explain h4 {
  font-family: "Anonyms Pro", monospace;
  margin-top: 30px;
}
.info-of-skills {
  display: flex;
  text-align: left;
  position: relative;
}
.info-of-skills p {
  padding: 15px;
}
@media only screen and (min-device-width: 769px) and (max-device-width: 1024px) {
  .info-of-skills p {
    padding: 8px;
    text-align: right;
  }
}
@media only screen and (min-device-width: 481px) and (max-device-width: 768px) {
  .info-of-skills {
    width: 80%;
  }
  .info-of-skills p {
    padding: 15px;
    font-size: 0.9em;
    margin-left: -10px;
  }
}
@media only screen and (min-device-width: 200px) and (max-device-width: 480px) {
  .info-of-skills {
    display: inline;
  }
  .info-of-skills p {
    padding: 5px;
  }
  .ending {
    float: right;
  }
}
